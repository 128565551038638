
export default class ECommerceConf {

    constructor() {

    }

    getServerURL() {
        return 'http://lavar.click';
    }
}

<template>
  <b-overlay
      :show="loading"
      rounded="sm" 
    >
<div class="auth-wrapper auth-v1 px-2">

    <div class="auth-inner py-2">

    <!-- Login v1 -->
    <b-card class="mb-0">
        <b-link class="brand-logo">

        <vuexy-logo />

        <!-- <h2 class="brand-text text-primary ml-1">
            Vuexy
        </h2> -->
        </b-link>

        <b-card-title class="mb-1">
        Bienvenido a LavarClick CONSOLE ! 👋
        </b-card-title>
        <b-card-text class="mb-2">
        <div style="text-align: center !important; width: 100% !important;" >Por Favor Ingrese sus Credenciales</div>
        </b-card-text>

        <!-- form -->
        <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="email@empresa.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Olvidaste tu Contraseña ?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Contraseña"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Recuerdame
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Ingresar
              </b-button>
            </b-form>
          </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
        <span>New on our platform? </span>
        <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
        </b-link>
        </b-card-text> -->

        <div class="divider my-2">
        <div class="divider-text">
            LavarClick Social !
        </div>
        </div>

        <!-- social button -->
        <div class="auth-footer-btn d-flex justify-content-center">
        <b-button
            href="javascript:void(0)"
            variant="facebook"
        >
            <feather-icon icon="FacebookIcon" />
        </b-button>
        <b-button
            href="javascript:void(0)"
            variant="twitter"
        >
            <feather-icon icon="TwitterIcon" />
        </b-button>
        <b-button
            href="javascript:void(0)"
            variant="google"
        >
            <feather-icon icon="MailIcon" />
        </b-button>
        <!-- <b-button
            href="javascript:void(0)"
            variant="github"
        >
            <feather-icon icon="GithubIcon" />
        </b-button> -->
        </div>
    </b-card>
    <!-- /Login v1 -->
    </div>
</div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow, BOverlay, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip, BCard
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AccountsManager from '@/lavarclick/ecommerce/AccountsManager.ts';

export default {
    directives: {
    'b-tooltip': VBTooltip,
    },
    components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg, BOverlay,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BCard
    },
    mixins: [togglePasswordVisibility],
    data() {
    return {
        loading: false,
        status: '',
        password: '',
        userEmail: '',
        sideImg: require('@/assets/images/pages/login-v2.svg'),

        // validation rules
        required,
        email,
        accountsManager: new AccountsManager()
    }
    },
    computed: {
    passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
        }
        return this.sideImg
    },
    },
    methods: {
    login() {

      this.loading = true;
    this.accountsManager.authenticateUser(this.userEmail, this.password).then(success => {

        if (success.id > 0) {

            console.log(success);
            useJwt.login({
            email: this.userEmail,
            password: this.password,
            userPre: success
            })
            .then(response => {
                console.log('si ', response.data);
                const { accessToken, refreshToken, userData } = response.data;

                this.accountsManager.getUser(userData.id).then((currentUser) => {

                this.accountsManager.getCustomer(1).then((customer) => {
                    userData.avatar = currentUser.avatar;
                    userData.fullName = currentUser.names+ ' '+currentUser.surnames;
                    userData.customer = customer;
                    userData.scanclick_user = currentUser;
                    useJwt.setToken(accessToken)
                    useJwt.setRefreshToken(refreshToken)
                    console.log('userdata ', userData);
                    localStorage.setItem('userData', JSON.stringify(userData))
                    this.$ability.update(userData.ability);

                    // if(userData.id_area === 4) {
                    // userData.role = 'employee';
                    // }

                    // ? This is just for demo purpose as well.
                    // ? Because we are showing eCommerce app's cart items count in navbar
                    // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                    // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                    this.$router.replace(getHomeRouteForLoggedInUser(userData.role)).then(() => {
                      this.loading = false;
                        this.$toast({
                        component: ToastificationContent,
                        position: 'middle-right',
                        props: {
                            title: `Bienvenido a LavarClick CONSOLE ${userData.fullName || userData.username}`,
                            icon: 'CoffeeIcon',
                            variant: 'success',
                            text: `Has Iniciado Sesion en la Plataforma !`,
                        },
                        })
                    });
                });
                });
            })
            .catch(error => {
                this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }else if(success.id === -1) {
            this.loading = false;
            this.$refs.loginForm.setErrors({
            email: ['Email o Password Invalidos. Por Favor Verifique']
            })
        }else if(success.id === -2) {
          this.loading = false;
          this.$refs.loginForm.setErrors({
            email: ['No has Confirmado tu Email']
            })
        }
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>